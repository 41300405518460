<template>
  <div v-frag>
    <v-dialog
      v-model='open'
      max-width='500px'
      persistent
      scrollable
      transition='dialog-bottom-transition'
    >
      <v-card>
        <v-card-title>
          <span class='headline'>Verify Bank Account</span>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-subtitle>
          Please verify in your bank account the two micro transfers you should
          have received and enter the amount below.
        </v-card-subtitle>
        <v-card-text class='py-2 px-4'>
          <v-container>
            <v-form ref='form' v-model='valid' lazy-validation>
              <v-row>
                <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                  <p class='font-weight-bold'>Amount 1</p>
                  <v-currency-field
                    ref='amount'
                    v-model='amount1'
                    :rules='amountRules'
                    class='currencyInput'
                    outlined
                    prefix='$'
                  />
                </v-col>
                <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                  <p class='font-weight-bold'>Amount 2</p>
                  <v-currency-field
                    ref='amount'
                    v-model='amount2'
                    :rules='amountRules'
                    class='currencyInput'
                    outlined
                    prefix='$'
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class='d-flex direction-row justify-center'>
          <v-btn
            class='px-14 mx-4 font-weight-bold'
            color='primary'
            large
            outlined
            @click='cancel'
          >
            Cancel
          </v-btn>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='verify'
          >
            Verify
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import charityService from '../../../services/charity/index'

export default {
  props: ['open', 'id'],
  data() {
    return {
      valid: true,
      amount1: 0,
      amount2: 0,
      amountRules: [(v) => !!v || 'Amount is required']
    }
  },
  methods: {
    async verify() {
      if (this.valid) {
        const data = {
          charityId: this.id,
          amount1: this.amount1 * 100,
          amount2: this.amount2 * 100
        }
        const res = await charityService.verifyBankAccount(data)
        this.$emit('verifyRes', res)
      }
    },
    cancel() {
      this.$emit('cancel')
      this.$refs.form.reset()
    }
  }
}
</script>
