<template>
  <div v-frag>
    <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
      <v-card elevation='0'>
        <v-container fluid>
          <v-card-title>
            <v-row align='center' align-content='center' justify='center'>
              <v-col cols='12' lg='8' md='8' sm='12' xl='8' xs='12'>
                <div
                  class='text-h5 font-weight-black grey--text text--darken-1'
                >
                  {{ sections.fundraising_needs_profile || 'Fundraising Needs title' }}
                </div>
              </v-col>
              <v-col cols='12' lg='4' md='4' sm='12' xl='4' xs='12'>
                <v-btn
                  class='white--text body-1 text-capitalize font-weight-bold'
                  color='primary'
                  elevation='0'
                  outlined
                  style='background-color: white; float: right'
                  to='/fundraising-needs'
                  x-large
                >
                  Manage Fundraising Needs
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-card
              v-for='(item, index) in items'
              :key='index'
              class='rounded-lg pt-10 pl-5 pb-10 mt-5'
              elevation='0'
              style='border: 1px solid #ecf1f9'
            >
              <v-row align='start' align-content='start' justify='start'>
                <v-col cols='12' lg='4' md='4' sm='12' xl='4' xs='12'>
                  <v-row align='center' align-content='center' justify='center'>
                    <v-col cols='auto'>
                      <v-avatar color='primary' size='250'>
                        <img :src='item.image'/>
                      </v-avatar>
                    </v-col>
                    <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                      <v-card class='rounded-pill' elevation='0'>
                        <v-container>
                          <v-row
                            align='center'
                            align-content='center'
                            dense
                            justify='center'
                          >
                            <v-col
                              cols='12'
                              lg='12'
                              md='12'
                              sm='12'
                              xl='12'
                              xs='12'
                            >
                              <div
                                class='
                                  text-h6
                                  font-weight-black
                                  primary--text
                                  text-center text--darken-1
                                '
                              >
                                {{ item.percentage }}%
                              </div>
                            </v-col>
                            <v-col
                              cols='12'
                              lg='12'
                              md='12'
                              sm='12'
                              xl='12'
                              xs='12'
                            >
                              <v-progress-linear
                                :value='item.percentage'
                                class='rounded-lg'
                                color='primary'
                                height='15'
                                rounded
                              ></v-progress-linear>
                            </v-col>
                            <v-col
                              cols='12'
                              lg='6'
                              md='6'
                              sm='12'
                              xl='6'
                              xs='12'
                            >
                              <div class='grey--text text-left text--ligth-1'>
                                {{ item.table[0].amountRaised }}
                              </div>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col
                              cols='12'
                              lg='6'
                              md='6'
                              sm='12'
                              xl='6'
                              xs='12'
                            >
                              <div class='grey--text text-right text--ligth-1'>
                                {{ item.table[0].fundsNeeded }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols='12' lg='8' md='8' sm='12' xl='8' xs='12'>
                  <v-row>
                    <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                      <v-card
                        class='rounded-lg'
                        elevation='0'
                        style='border: 2px solid #ecf1f9'
                      >
                        <v-data-table
                          :headers='headers'
                          :items='item.table'
                          class='elevation-0'
                          hide-default-footer
                        >
                        </v-data-table>
                      </v-card>
                    </v-col>
                    <v-col cols='12' lg='11' md='11' sm='12' xl='11' xs='12'>
                      <v-card
                        class='rounded-lg'
                        elevation='0'
                        style='border: 2px solid #ecf1f9'
                      >
                        <v-card-title>
                          <div
                            class='
                              text-body-1
                              font-weight-black
                              grey--text
                              text--darken-1
                            '
                          >
                            Description
                          </div>
                        </v-card-title>
                        <v-card-text style='border-top: 2px solid #ecf1f9'>
                          <div class='mt-5'>
                            <p
                              v-for='(
                                description, indexDescription
                              ) in item.description'
                              :key='indexDescription'
                            >
                              {{ description.text }}
                            </p>
                            <!-- <p>
                              The grief and loss center prepares for our
                              Christmas holiday party and we need volunteer hep
                              to setup our property for this event. Employees
                              voluneteers will be decorating and moving office
                              furniture in preparation for this event.
                            </p> -->
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
        </v-container>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import charity from '../../../services/charity'
import {mapState} from 'vuex'

export default {
  async mounted() {
    await this.loadInitial()
  },
  data() {
    return {
      items: [],
      headers: [
        {
          text: 'Need',
          align: 'center',
          sortable: false,
          value: 'need',
          divider: true
        },
        {
          text: 'Funds needed',
          align: 'center',
          sortable: false,
          value: 'fundsNeeded',
          divider: true
        },
        {
          text: 'Amount Raised',
          align: 'center',
          sortable: false,
          value: 'amountRaised',
          divider: true
        },
        {
          text: 'Deadline',
          align: 'center',
          sortable: false,
          value: 'deadline',
          divider: true
        }
      ]
    }
  },
  computed: {
    ...mapState('cmsStore', ['sections'])
  },
  methods: {
    async loadInitial() {
      try {
        const res = await charity.getFundraising()
        this.items = res
      } catch (err) {
        // empty
      }
    }
  }
}
</script>

<style scoped>
.shadow {
  -webkit-box-shadow: -1px 0px 22px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 0px 22px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 0px 22px 0px rgba(0, 0, 0, 0.75);
}
</style>
