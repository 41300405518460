<template>
  <div v-frag>
    <dialog-edit-profile/>
    <v-container fluid>
      <Alert/>
      <v-row>
        <v-col cols='12' lg='5' md='5' sm='12' xl='5' xs='12'>
          <v-row>
            <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
              <v-card class='rounded-lg' elevation='0'>
                <v-container>
                  <v-card-title>
                    <v-row
                      align='center'
                      align-content='center'
                      justify='center'
                    >
                      <v-col cols='12' lg='6' md='12' sm='12' xl='6' xs='12'>
                        <v-avatar
                          color='primary'
                          size='200'
                          style='cursor: pointer'
                          @click='openImage'
                        >
                          <v-img :src='logo' contain/>
                        </v-avatar>
                      </v-col>
                      <v-col cols='12' lg='6' md='12' sm='12' xl='6' xs='12'>
                        <div
                          class='
                            text-h5
                            font-weight-black
                            grey--text
                            text--darken-1 text-center
                          '
                        >
                          {{ name }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-divider class='mx-4'></v-divider>

                  <v-card-text>
                    <v-row>
                      <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                        <div
                          class='
                            text-h6
                            font-weight-black
                            grey--text
                            text--darken-1
                          '
                        >
                          Address
                        </div>
                      </v-col>
                      <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                        <div class='text-subtitle-1 grey--text text--darken-1'>
                          {{ address }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider class='mx-4'></v-divider>
                  <v-card-text>
                    <v-row>
                      <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                        <div
                          class='
                            text-h6
                            font-weight-black
                            grey--text
                            text--darken-1
                          '
                        >
                          Phone
                        </div>
                      </v-col>
                      <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                        <div class='text-subtitle-1 grey--text text--darken-1'>
                          {{ phone }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
              <v-card elevation='0'>
                <v-container fluid>
                  <v-card-title>
                    <div
                      class='
                        text-h5
                        font-weight-black
                        grey--text
                        text--darken-1
                      '
                    >
                      {{ sections.mission || 'Mission title' }}
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <div
                      class='grey--text text--darken-1 text-body-1 font-italic'
                    >
                      “{{ mission }}”...
                    </div>
                  </v-card-text>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols='12' lg='7' md='7' sm='12' xl='7' xs='12'>
          <v-row>
            <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
              <v-row>
                <v-col cols='12' lg='2' md='2' sm='12' xl='2' xs='12'>
                  <v-btn
                    :width="small ? '100%' : '100%'"
                    class='white--text body-1 text-capitalize font-weight-bold'
                    color='primary'
                    elevation='0'
                    outlined
                    style='background-color: white; float: right'
                    x-large
                    @click='openDialog'
                  >
                    Edit Profile
                  </v-btn>
                </v-col>

                <v-col
                  v-if='bankAccount && !bankAccount.status_account'
                  class='pr-0'
                  cols='12'
                  lg='4'
                  md='4'
                  sm='12'
                  xl='4'
                  xs='12'
                >
                  <v-btn
                    :disabled='isVerified'
                    :width="small ? '100%' : '100%'"
                    class='white--text body-1 text-capitalize font-weight-bold'
                    color='primary'
                    elevation='0'
                    outlined
                    style='background-color: white; float: right'
                    x-large
                    @click='openDialogVerify'
                  >
                    {{ isVerified ? 'Verified successfully' : 'Verify Bank Account' }}
                  </v-btn>
                </v-col>
                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <v-btn
                    :width="small ? '100%' : '100%'"
                    class='white--text body-1 text-capitalize font-weight-bold'
                    color='primary'
                    elevation='0'
                    outlined
                    style='background-color: white; float: right'
                    x-large
                    @click='dialog = true'
                  >
                    Edit Bank Account information
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
              <v-card elevation='0'>
                <v-container>
                  <v-row align='center' align-content='center' justify='center'>
                    <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                      <v-card-title>
                        <div
                          class='
                            text-h5
                            font-weight-black
                            grey--text
                            text--darken-1
                          '
                        >
                          {{ sections.description || 'Description title' }}
                        </div>
                      </v-card-title>
                    </v-col>
                  </v-row>
                  <v-card-text>
                    <div
                      class='grey--text text--darken-1 text-body-1'
                      v-html='description'
                    ></div>
                  </v-card-text>
                </v-container>
              </v-card>

              <v-card
                v-if='bankAccount && !bankAccount.status_account'
                class='mt-6'
                elevation='0'
              >
                <v-container>
                  <v-row align='center' align-content='center' justify='center'>
                    <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                      <v-card-title>
                        <div
                          class='
                            text-h5
                            font-weight-black
                            grey--text
                            text--darken-1
                          '
                        >
                          {{ sections.bank_account_status || 'Bank Account title' }}
                        </div>
                      </v-card-title>
                    </v-col>
                  </v-row>
                  <v-card-text>
                    <div class='grey--text text--darken-1 text-body-1'>
                      {{ isVerified ? 'Verified successfully' : bankAccountStatus }}
                    </div>
                  </v-card-text>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-card elevation='0'>
            <table-volunteering-opportunities/>
          </v-card>
        </v-col>
        <open-fundraising-needs/>
      </v-row>
      <div style='display: none'>{{ alert.show }}</div>
    </v-container>

    <div v-frag>
      <DialogEditBankAccount
        :dialog='dialog'
        v-on:close='dialog = false'
        v-on:updated='getProfileBankAccount'
      />

      <DialogVerifyBankAccount
        :id='id'
        :open='dialogVerifyBankAccount'
        v-on:cancel='dialogVerifyBankAccount = false'
        v-on:verifyRes='handleVerifyRes'
      />
    </div>

    <v-overlay :value='overlay' :z-index='9999'>
      <v-card color='transparent' elevation='0'>
        <v-btn
          class='float-right mr-2 mb-2'
          color='white'
          icon
          large
          @click='overlay = false'
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-text>
          <v-img
            :src='logoView'
            lazy-src='http://beepeers.com/assets/images/commerces/default-image.jpg'
            max-height='500'
            max-width='700'
            width='700'
          >
            <template v-slot:placeholder>
              <v-row align='center' class='fill-height ma-0' justify='center'>
                <v-progress-circular
                  color='grey lighten-5'
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card-text>
      </v-card>
    </v-overlay>
  </div>
</template>
<script>
import TableVolunteeringOpportunities from './components/TableVolunteeringOpportunities'
import OpenFundraisingNeeds from './components/OpenFundraisingNeeds'
import DialogVerifyBankAccount from './components/DialogVerifyBankAccount.vue'
import charity from '../../services/charity'
import DialogEditProfile from './components/DialogEditProfile'
import DialogEditBankAccount from './components/DialogEditBankAccount'
import Alert from '../../components/Alert'
import {mapState} from 'vuex'

export default {
  components: {
    TableVolunteeringOpportunities,
    OpenFundraisingNeeds,
    DialogEditProfile,
    Alert,
    DialogVerifyBankAccount,
    DialogEditBankAccount
  },
  async mounted() {
    await this.initialLoad()
  },
  computed: {
    ...mapState('cmsStore', ['sections']),
    small() {
      if (
        this.$vuetify.breakpoint.name == 'xs' ||
        this.$vuetify.breakpoint.name == 'sm'
      ) {
        return true
      } else {
        return false
      }
    },
    alert() {
      if (this.$store.getters.alert.response) {
        this.initialLoad()
      }
      return this.$store.getters.alert
    },
    logoView() {
      const logo =
        this.logo == ''
          ? 'http://beepeers.com/assets/images/commerces/default-image.jpg'
          : this.logo
      return logo
    }
  },
  data() {
    return {
      logo: '',
      name: '',
      address: '',
      phone: '',
      mission: '',
      description: '',
      bankAccountStatus:
        'Your bank account is being verified, you will receive two micro transfers in order to verify it. Please check in your account and then go to "Verify Bank Account" to enter the amounts of the mentioned transfers. This transfer process may take a few days.',
      bankAccount: null,
      id: null,
      dialogVerifyBankAccount: false,
      dialog: false,
      valid: true,
      inputs: {
        name: '',
        account: '',
        country: 'US',
        currency: 'USD',
        company: 'Company'
      },
      nameRules: [(v) => !!v || 'name is required'],
      accountRules: [(v) => !!v || 'Phone is required'],
      loading: false,
      overlay: false,
      isVerified: false
    }
  },
  methods: {
    async initialLoad() {
      try {
        const res = await charity.getProfile()
        this.bankAccount = this.$store.getters.charityBankAccount
        this.id = res.id
        this.logo = res.logo
        this.name = res.name
        this.address = res.address
        this.phone = res.contact_phone
        this.mission = res.mission
        this.description = res.description
        this.isVerified = res.verification
      } catch (e) {
        //   empty
      }
    },
    openDialog() {
      let text = 'Edit Profile'
      this.$store.dispatch('crud', {
        dialog: true,
        title: text,
        id: null
      })
    },
    openDialogVerify() {
      this.dialogVerifyBankAccount = true
    },
    handleVerifyRes(data) {
      if (data.success) {
        this.$store.dispatch('alert', {
          show: true,
          color: 'green',
          title: 'Success!',
          message: data.message,
          response: false
        })
      } else {
        this.$store.dispatch('alert', {
          show: true,
          color: 'red',
          title: 'Something went wrong!!',
          message: data.data.message,
          response: false
        })
      }
      this.dialogVerifyBankAccount = false
    },
    async getProfileBankAccount() {
      const res = charity.getProfileBankAccount()
      this.bankAccount = res
      this.$store.dispatch('charityBankAccount', res)
    },
    openImage() {
      this.overlay = true
    }
  }
}
</script>
