<template>
  <v-container v-frag>
    <v-dialog
      v-model='dialog'
      max-width='1000px'
      persistent
      scrollable
      transition='dialog-bottom-transition'
    >
      <v-card>
        <v-card-title>
          <span class='headline'>Bank Account information</span>
        </v-card-title>
        <v-card-text class='py-2 px-4'>
          <v-container>
            <v-form ref='form' v-model='valid' lazy-validation>
              <v-row>
                <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                  <p class='font-weight-bold'>Name*</p>
                  <v-text-field
                    v-model='inputs.name'
                    :rules='nameRules'
                    dense
                    outlined
                    placeholder='John Mayer'
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <p class='font-weight-bold'>Account Number*</p>
                  <v-text-field
                    v-model='inputs.account'
                    v-mask="'######################'"
                    :rules='accountRules'
                    dense
                    maxlength='17'
                    oninput='if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);'
                    outlined
                    placeholder=''
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <p class='font-weight-bold'>ABA - Routing Number*</p>
                  <v-text-field
                    v-model='inputs.routing_number'
                    v-mask="'######################'"
                    :rules='accountRules'
                    dense
                    maxlength='9'
                    oninput='if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);'
                    outlined
                    placeholder='110000000'
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <p class='font-weight-bold'>Country</p>
                  <v-text-field
                    v-model='inputs.country'
                    dense
                    disabled
                    outlined
                    placeholder='country'
                    required
                    type='text'
                  ></v-text-field>
                </v-col>

                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <p class='font-weight-bold'>Currency</p>
                  <v-text-field
                    v-model='inputs.currency'
                    dense
                    disabled
                    outlined
                    placeholder='Currency'
                    required
                    type='text'
                  ></v-text-field>
                </v-col>

                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <p class='font-weight-bold'>Account holder type</p>
                  <v-text-field
                    v-model='inputs.company'
                    dense
                    disabled
                    outlined
                    placeholder='company'
                    required
                    type='text'
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class='d-flex direction-row justify-center'>
          <v-btn
            :disabled='loading'
            class='px-14 mx-4 font-weight-bold'
            color='primary'
            large
            outlined
            @click='closeDialog'
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled='loading'
            :loading='loading'
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='updated'
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import charityService from '../../../services/charity'

export default {
  props: ['dialog'],
  data() {
    return {
      valid: true,
      loading: false,
      inputs: {
        name: '',
        account: '',
        routing_number: '',
        country: 'US',
        currency: 'USD',
        company: 'Company'
      },
      nameRules: [(v) => !!v || 'Name is required'],
      accountRules: [(v) => !!v || 'Number is required']
    }
  },
  methods: {
    async initialize() {
      try {
        const res = await charityService.getProfileBankAccount()
        if (res) {
          let aux = {
            name: res.account_holder_name,
            account: res.account_number,
            routing_number: res.routing_number,
            country: 'US',
            currency: 'USD',
            company: 'Company'
          }
          this.inputs = aux
        }
      } catch (err) {
        // empty
      }
    },
    async updated() {
      this.loading = true
      if (this.$refs.form.validate()) {
        try {
          const res = await charityService.updatedProfileBankAccount(
            this.inputs
          )
          this.$store.dispatch('charityBankAccount', res.data.banckAccountApi)
          this.closeDialog()
          if (res.success) {
            this.$store.dispatch('alert', {
              show: true,
              color: 'green',
              title: 'Success message!',
              message: res.message,
              response: true
            })
            this.$emit('updated')
          }
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    closeDialog() {
      this.$emit('close')
    }
  },
  mounted() {
    this.initialize()
  }
}
</script>
